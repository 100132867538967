import { DataOptions } from './data-options';
import { HttpOptions } from './http-options';

export class ApiRequest {
  static defaultDataOptions: DataOptions = {
    loadingOverlayEnabled: true,
    loadingOverlayMessage: 'Loading...',
    toastOnSuccess: false,
    toastSuccessMessage: '',
    toastOnError: true,
    toastErrorMessage: '',
    errorMessageMap: undefined,
    startNewCorrelationId: false
  };
  static concealedCall: DataOptions = {
    loadingOverlayEnabled: false,
    toastOnSuccess: false,
    toastSuccessMessage: '',
    toastOnError: false,
    toastErrorMessage: '',
    errorMessageMap: undefined,
    startNewCorrelationId: false
  };
  serviceRootUri: string;
  body: object;
  queryParams: object;
  dataOptions: DataOptions;
  httpOptions: HttpOptions;

  constructor(
    serviceRootUrl?: string,
    queryParams?: object,
    options?: DataOptions,
    body?: object,
    httpOptions?: HttpOptions
  ) {
    this.serviceRootUri = serviceRootUrl;
    this.body = body;
    this.queryParams = queryParams || {};
    this.dataOptions = options || ApiRequest.defaultDataOptions;
    this.httpOptions = httpOptions;
  }

  get queryParamString(): string {
    return this.objectToQueryString(this.queryParams);
  }

  /**
   * Converts an object to query/form string format
   * eg: {a:'1',b:'2'} -> a=1&b=2
   */
  private objectToQueryString(data: any = {}): string {
    let queryString = '';
    const buildQueryElement = (key, value) => {
      return value || value === 0
        ? key + '=' + encodeURIComponent(value) + '&'
        : '';
    };
    Object.keys(data).forEach(element => {
      if (data[element] instanceof Array) {
        (data[element] as Array<any>).forEach(arrayElement => {
          queryString += buildQueryElement(element, arrayElement);
        });
      } else if (data[element] instanceof Date) {
        queryString += buildQueryElement(
          element,
          (data[element] as Date).getTime()
        );
      } else {
        queryString += buildQueryElement(element, data[element]);
      }
    });
    return queryString.slice(0, -1); // remove trailing '&' (easier to do here as opposed to testing all values on creation);
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataOptions } from "./data-options";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "./notification/notification.service";
import { UUID } from "angular2-uuid";
import { ApiRequest } from "./api-request";
import { ConfigManagerService } from "@xpo-ltl/config-manager";
import { of, Observable, throwError } from "rxjs";
import {
  tap,
  switchMap,
  map,
  catchError,
  take,
  finalize
} from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class DataApiService {
  private correlationId = "";

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private configManager: ConfigManagerService
  ) {
    this.correlationId = UUID.UUID();
  }

  /**
   * Generates a new correlation ID to be used.
   */
  public startNewCorrelationSession() {
    this.correlationId = UUID.UUID();
  }

  public getCorrelationId() {
    return this.correlationId;
  }

  //noinspection JSUnusedGlobalSymbols
  get(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(
      request.serviceRootUri,
      request.queryParamString
    );
    return of(request.dataOptions).pipe(
      tap(this.handlePreRequestDuties.bind(this)),
      switchMap(() => this.http.get(requestUrl, request.httpOptions as object)),
      map(this.extractData),
      catchError(this.handleError(request.dataOptions)),
      take(1),
      finalize(this.handlePostRequestDuties(request.dataOptions))
    );
  }

  //noinspection JSUnusedGlobalSymbols
  post(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(
      request.serviceRootUri,
      request.queryParamString
    );
    return of(request.dataOptions).pipe(
      tap(this.handlePreRequestDuties.bind(this)),
      switchMap(() =>
        this.http.post(requestUrl, request.body, request.httpOptions as object)
      ),
      map(this.extractData),
      catchError(this.handleError(request.dataOptions)),
      take(1),
      finalize(this.handlePostRequestDuties(request.dataOptions))
    );
  }

  //noinspection ReservedWordAsName, JSUnusedGlobalSymbols
  delete(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(
      request.serviceRootUri,
      request.queryParamString
    );
    return of(request.dataOptions).pipe(
      tap(this.handlePreRequestDuties.bind(this)),
      switchMap(() =>
        this.http.delete(requestUrl, request.httpOptions as object)
      ),
      map(this.extractData),
      catchError(this.handleError(request.dataOptions)),
      take(1),
      finalize(this.handlePostRequestDuties(request.dataOptions))
    );
  }

  //noinspection JSUnusedGlobalSymbols
  put(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(
      request.serviceRootUri,
      request.queryParamString
    );
    return of(request.dataOptions).pipe(
      tap(this.handlePreRequestDuties.bind(this)),
      switchMap(() =>
        this.http.put(requestUrl, request.body, request.httpOptions as object)
      ),
      map(this.extractData),
      catchError(this.handleError(request.dataOptions)),
      take(1),
      finalize(this.handlePostRequestDuties(request.dataOptions))
    );
  }

  private generateFullyQualifiedUrl(url: string = "", queryParams?: string) {
    const configuredApiUrl = this.configManager.getSetting("apiUrl") as string;
    const conditionedApiRoot = `${configuredApiUrl}${
      configuredApiUrl.endsWith("/") ? "" : "/"
    }`;
    const conditionedServiceUri = `${
      url.startsWith("/") ? url.substring(1) : url
    }`;
    const conditionedQueryParams = queryParams ? `?${queryParams}` : "";
    return `${conditionedApiRoot}${conditionedServiceUri}${conditionedQueryParams}`;
  }

  /**
   * Unwraps the payload from the API envelope.
   * This will check the response code if provided and ensure it's within the 200-series (not all responses or response types will have it)
   */
  private extractData(response: any) {
    if (!response) {
      return undefined;
    }
    const code = response["code"];
    // tslint:disable-next-line
    if (!code || (code & 200) === 200 || response["access_token"]) {
      if (response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return response;
      }
    } else {
      // TODO - I don't think this is correct.  The thrownError here is
      // not being caught since we are using a map() operator. This means that
      // we will get an observable of the error, not a thrown error.
      return throwError(response ? response.error : response);
    }
  }

  private handleError(
    options: DataOptions
  ): (error: HttpErrorResponse) => Observable<any> {
    const _this = this; // prevents the need for a .bind(this) call
    return (error: HttpErrorResponse): Observable<any> => {
      if (options.toastOnError) {
        let duration = 5000;
        if (
          options &&
          options.snackBarConfig &&
          options.snackBarConfig.durationInMillis
        ) {
          duration = options.snackBarConfig.durationInMillis;
        }
        if (
          error.status !== undefined &&
          options.errorMessageMap != null &&
          options.errorMessageMap.get(error.status)
        ) {
          _this.notificationService.showSnackBarMessage(
            options.errorMessageMap.get(error.status),
            { status: "error", durationInMillis: duration }
          );
        } else if (options.toastErrorMessage) {
          _this.notificationService.showSnackBarMessage(
            options.toastErrorMessage,
            { status: "error", durationInMillis: duration }
          );
        } else if (
          error.error != null &&
          error.error.error != null &&
          error.error.error.message != null
        ) {
          _this.notificationService.showSnackBarMessage(
            `Error: ${error.error.error.message}`,
            { status: "error", durationInMillis: duration }
          );
        } else if (error.statusText != null) {
          _this.notificationService.showSnackBarMessage(
            `Error: ${error.statusText}`,
            { status: "error", durationInMillis: duration }
          );
        } else {
          _this.notificationService.showSnackBarMessage(
            "System error. Please contact support.",
            { status: "error", durationInMillis: duration }
          );
        }
      }
      return throwError(error.error || error);
    };
  }

  private handlePreRequestDuties(options: DataOptions): void {
    if (options.startNewCorrelationId) {
      this.startNewCorrelationSession();
    }
    if (options.loadingOverlayEnabled) {
      this.notificationService.showOverlayMessage(
        options.loadingOverlayMessage
      );
    }
  }

  private handlePostRequestDuties(options: DataOptions): () => void {
    const _this = this; // prevents the need for a .bind(this) call
    return () => {
      if (options.toastOnSuccess) {
        _this.notificationService.showSnackBarMessage(
          options.toastSuccessMessage,
          options.snackBarConfig
        );
      }
      if (options.loadingOverlayEnabled) {
        _this.notificationService.hideOverlayMessage();
      }
    };
  }
}
